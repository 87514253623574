import React from "react";
import { Link, graphql } from "gatsby";
import Pagination from "../components/pagination";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubscriptionForm from "../components/subscriptionForm";
import Footer from "../components/footer";

export const query = graphql`
  query Posts($skip: Int, $limit: Int) {
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          date(formatString: "MMM Do, YYYY")
          title
          excerpt
          slug
        }
      }
    }
  }
`;

const Posts = ({ data, pageContext }) => {
  if (!data) return null;
  return (
    <Layout>
      <SEO
        title="Blog | Chloe"
        description="Chloe is a web developer. She's just started blogging because she recklessly put yet another empty gatsby x wordpress project on the internet."
      />
      <div className="text-green flex pt-16 md:pt-20 lg:pt-24 px-8 sm:px-16 flex-col content flex-grow">
        <div className="flex-grow">
          <div className="grid">
            {data.allWordpressPost.edges.map(({ node }) => (
              <div key={node.slug} className="">
                <div className="text-xs uppercase mb-2">{node.date}</div>
                <Link to={`blog/${node.slug}`}>
                  <div
                    className="text-lg mb-4 title font-semibold italic uppercase"
                    dangerouslySetInnerHTML={{ __html: node.title }}
                  ></div>
                  <hr />
                  <div
                    className="text-xs"
                    dangerouslySetInnerHTML={{ __html: node.excerpt }}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <Pagination
          pageCount={pageContext.pageCount}
          currentPage={pageContext.currentPage}
        />
        <SubscriptionForm />
        <Footer />
      </div>
    </Layout>
  );
};
export default Posts;
