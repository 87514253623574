import React from 'react';
import { Link } from 'gatsby';

const Pagination = ({ currentPage, pageCount }) => (
  <nav className="uppercase flex justify-between text-xs font-semibold text-green mb-4 mt-8">
    {currentPage > 1 ? (
      <Link title="Go to previous page" to={`/blog/page/${currentPage - 1}`}>
        ← Newer posts
      </Link>
    ) : (
      <span style={{ maxWidth: '117px' }} />
    )}
    <p
      className="text-center"
      style={{ minWidth: '117px' }}
    >{`Page ${currentPage} of ${pageCount}`}</p>
    {currentPage < pageCount ? (
      <Link title="Go to next page" to={`/blog/page/${currentPage + 1}`}>
        Older posts →
      </Link>
    ) : (
      <span style={{ maxWidth: '113px' }} />
    )}
  </nav>
);

export default Pagination;
